import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import lodashStartCase from "lodash/startCase";
import lodashCapitalize from "lodash/capitalize";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Dropdown,
  Grid,
  Icon,
  Label,
  Message,
  Modal,
  Popup,
} from "semantic-ui-react";
import CustomDropdown from "./CustomDropdown";
import ACL_RELATIONSHIPS from "../acl-relationships";
import taskConstants from "../constants/Task";
import { startCase, titleCase } from "../helpers/string";
import _EditCallModal from "./modals/EditCallModal";

import ContactService from "../services/Contact";
import AudioElement from "./AudioElement";
import isNil from "lodash/isNil";
import NoteItem from "./NoteItem";
import RvxTag from "./RvxTag";
import {
  capitalize,
  createDateTimeFormatter,
  formatDate,
  getFormEvents,
  LoadingMessage,
  roundToTwoDecimals,
} from "./helpers";
import withRoleCheck from "./hocs/withRoleCheck";
import { NewNoteModal as _NewNoteModal } from "./modals/NoteModals";
import { parseWixForm } from "./wixForms/helpers";
import CampaignStatusModal from "routes/campaign/components/CampaignStatusModal";
import axios from "axios";
import { readEml } from "eml-parse-js";
import CampaignService from "services/Campaign";

const NewNoteModal = withRoleCheck(_NewNoteModal, [
  ACL_RELATIONSHIPS.activity.create,
]);
const EditCallModal = withRoleCheck(_EditCallModal, [
  ACL_RELATIONSHIPS.campaignTargetCall.edit,
]);

const defaultDateTimeFormatter = createDateTimeFormatter();
const basicDateFormatter = createDateTimeFormatter({ format: "YYYY-MM-DD" });

const MergedLabel = ({ item }) => {
  const getMessage = info => {
    let mergedContacts = "";
    let mergedEntities = "";

    if (info._merged_from_contact) {
      mergedContacts = `Merged from contacts: #${info._merged_from_contact.join(
        ", #"
      )}\n`;
    }

    if (info._merged_from_entity) {
      mergedEntities = `Merged from entities: #${info._merged_from_entity.join(
        ", #"
      )}\n`;
    }

    return `${mergedContacts} ${mergedEntities}`;
  };

  return (
    <Popup
      content={getMessage(item.info)}
      trigger={
        <Label
          size="mini"
          style={{ backgroundColor: "#fb8072" }}
          content={"Merged"}
          className="ctag"
        />
      }
    />
  );
};

const ViewEmailModal = ({ emailId, emailUrl }) => {
  const [htmlContent, setHtmlContent] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (emailUrl) {
      getEML(emailUrl);
    } else if (emailId) {
      getEmailHTML(emailId);
    }
    setOpen(true);
  };

  const getEML = async emailUrl => {
    const { data } = await axios.get(emailUrl);
    readEml(data, (err, EmlJson) => {
      if (err) {
        setEmailError(true);
      } else {
        setHtmlContent(EmlJson.html);
      }
    });
  };

  const getEmailHTML = async emailId => {
    try {
      const data = await CampaignService.getEmailData(emailId);
      setHtmlContent(data.html_content);
    } catch (e) {
      setEmailError(true);
    }
  };

  return (
    <>
      <Modal
        trigger={
          <Button
            style={{
              visibility: !!(emailUrl || emailId) ? "visible" : "hidden",
            }}
            basic
            size="tiny"
            content="View Email Sent"
            onClick={handleOpen}
          />
        }
        open={open}
        closeOnDimmerClick={false}
        onClose={() => setOpen(false)}
        closeIcon
      >
        <Modal.Header>Email Content</Modal.Header>
        <Modal.Content>
          {(emailError && (
            <Message
              color="red"
              header="Error"
              content="Could not load email"
            />
          )) ||
            (htmlContent && (
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlContent,
                }}
              />
            )) || <LoadingMessage />}
        </Modal.Content>
      </Modal>
    </>
  );
};

const actionMap = {
  delivered: {
    title: "Email Delivery",
    icon: "mail outline",
    message: ({
      contact_name,
      email_subject,
      contact_id,
      user_name,
      info: { is_manual, subject, email, date, from_email, comments },
    }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <div>
          {is_manual ? (
            <>
              <p>
                Model: <b>Email</b>
              </p>
              <div>
                <b>{user_name}</b> sent to <b>{email}</b> -{" "}
                {email_subject || subject}
              </div>
              <div>
                Contact: <b>{contactLink}</b>
              </div>
              <div>
                Date: <b>{date ? basicDateFormatter(date) : ""}</b>
              </div>
              <div>
                From email: <b>{from_email}</b>
              </div>
              <div>
                Comments: <b>{comments}</b>
              </div>
            </>
          ) : (
            <>
              {user_name ? (
                <p>
                  {user_name} sent {contactLink} {email_subject || subject}
                </p>
              ) : (
                <p>
                  {contactLink} was SENT {email_subject || subject}
                </p>
              )}{" "}
            </>
          )}
        </div>
      );
    },

    buttons: ({
      contact_id,
      auditable_id,
      modelType,
      modelId,
      logId,
      email,
      item,
      campaign_id,
      entity_id,
    }) => {
      const { info } = item;
      const { is_manual } = info;

      return (
        <>
          <ViewEmailModal
            emailId={info?.campaign_target_email_id}
            emailUrl={info?.s3_info?.url}
            campaignId={campaign_id}
            entityId={entity_id}
          />
          <NewNoteModal
            modelType={modelType}
            modelId={modelId}
            refersToModel="AuditLog"
            refersToID={logId}
            trigger={<Button basic size="tiny" content="Create Note" />}
          />
          {!is_manual && (
            <Modal
              trigger={
                <Button
                  onClick={() =>
                    ContactService.resendEmail(contact_id, auditable_id)
                  }
                  basic
                  size="tiny"
                  content="Resend"
                  disabled={!email}
                />
              }
              closeIcon
            >
              <Modal.Content>Email Sent</Modal.Content>
            </Modal>
          )}
          {info?.s3_info?.url && (
            <Button
              basic
              size="tiny"
              content="Download EML"
              onClick={() => window.open(info?.s3_info?.url, "_blank")}
            />
          )}
        </>
      );
    },
  },
  opened: {
    title: "Email Opened",
    icon: "mail outline",
    message: ({ contact_name, email_subject, contact_id }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <p>
          {contactLink} OPENED Email {email_subject}
        </p>
      );
    },
  },
  clicked: {
    title: "Email Clicked",
    icon: "mail outline",
    message: ({ contact_name, contact_id, email_subject, info: { url } }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <p>
          {contactLink} CLICKED Link {url} in Email {email_subject}
        </p>
      );
    },
  },
  custom_field_modified: {
    title: "Custom Field Modified (Bulk)",
    icon: "edit",
    message: ({
      user_name,
      contact_id,
      contact_name,
      entity_id,
      entity_name,
      info: { changes },
    }) => {
      changes = flattenChanges(changes, {
        custom_fields: {
          label: "custom field",
          mapValue: value => {
            try {
              if (typeof value.join === "function") {
                return value.join("' : '");
              } else {
                return value;
              }
            } catch (e) {
              console.error(e);
            }
          },
        },
      });
      return getInfoChangedMessage({
        user_name,
        contact_id,
        contact_name,
        entity_id,
        entity_name,
        changes,
      });
    },
  },
  custom_field_deleted: {
    title: "Custom Field Deleted (Bulk)",
    icon: "delete",
    message: ({
      user_name,
      contact_id,
      contact_name,
      entity_id,
      entity_name,
      info: { changes },
    }) => {
      changes = flattenChanges(changes, {
        custom_fields: {
          label: "custom field",
          mapValue: value => {
            try {
              if (typeof value.join === "function") {
                return value.join("' : '");
              } else {
                return value;
              }
            } catch (e) {
              console.error(e);
            }
          },
        },
      });
      return getInfoChangedMessage({
        user_name,
        contact_id,
        contact_name,
        entity_id,
        entity_name,
        changes,
      });
    },
  },
  errored: {
    title: "Email Error",
    icon: "mail outline",
    message: ({
      contact_name,
      info: { error_msg, bounce_description, cc_bcc_data },
      contact_id,
    }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });

      const bounceMessage = `(reason: ${bounce_description})`;
      return (
        <div>
          {cc_bcc_data ? (
            <p>
              Email sent to {cc_bcc_data.to_addr} as {cc_bcc_data.email_type}{" "}
              email failed: {error_msg}`
            </p>
          ) : (
            <p>
              Email delivery to {contactLink} failed: {error_msg}
            </p>
          )}
          {bounce_description && <span>{bounceMessage}</span>}
        </div>
      );
    },
  },
  submitted: {
    title: "Form Response",
    icon: "wpforms",
    message: ({ contact_name, form_name }) =>
      `${contact_name} SUBMITTED a response ${
        form_name ? "to " + form_name : ""
      }`,
    buttons: ({
      modelType,
      modelId,
      logId,
      ctfr_id,
      ctfr_info,
      form_language,
    }) => (
      <>
        <Modal
          trigger={<Button basic size="tiny" content="View Response" />}
          closeIcon
          size="fullscreen"
        >
          <Modal.Header>Response</Modal.Header>
          <Modal.Content>
            {ctfr_info
              ? parseWixForm(
                  getFormEvents(ctfr_info)[0],
                  ctfr_id,
                  form_language
                )
              : ""}
          </Modal.Content>
        </Modal>
        <NewNoteModal
          modelType={modelType}
          modelId={modelId}
          refersToModel="AuditLog"
          refersToID={logId}
          trigger={
            <Button basic size="tiny" content="Create Note from Activity" />
          }
        />
        {/*<Button basic size="tiny" content="Create Task from Activity" />*/}
      </>
    ),
  },
  form_edited: {
    title: "Edited Form",
    icon: "wpforms",
    message: ({ user_name, form_name }) =>
      `${user_name} EDITED a response ${form_name ? "to " + form_name : ""}`,
  },
  "attachment.created": {
    title: "Attachment Created",
    icon: "attach",
    message: ({
      user_name,
      attachment_info: { file_name },
      model_id,
      model_type,
      campaign_id,
    }) => (
      <p>
        {user_name} added '{file_name}' in{" "}
        {getModelLink(model_id, model_type, { campaign_id })}
      </p>
    ),
  },
  "attachment.deleted": {
    title: "Attachment Deleted",
    icon: "attach",
    message: ({
      user_name,
      attachment_info: { file_name },
      model_id,
      model_type,
      campaign_id,
    }) => (
      <p>
        {user_name} deleted '{file_name}' in{" "}
        {getModelLink(model_id, model_type, { campaign_id })}
      </p>
    ),
  },
  "activity_log.created": {
    title: "Note Created",
    icon: "sticky note outline",
    message: ({ user_name, model_id, model_type, campaign_id }) => (
      <p>
        {user_name} added a new Note in{" "}
        {getModelLink(model_id, model_type, { campaign_id })}
      </p>
    ),
  },
  "activity_log.modified": {
    title: "Note Modified",
    icon: "sticky note outline",
    message: ({ user_name, model_id, model_type, campaign_id }) => (
      <p>
        {user_name} edited a Note in{" "}
        {getModelLink(model_id, model_type, { campaign_id })}
      </p>
    ),
  },
  "activity_log.deleted": {
    title: "Note Deleted",
    icon: "sticky note outline",
    message: ({ user_name, model_id, model_type, campaign_id }) => (
      <p>
        {user_name} deleted a Note in{" "}
        {getModelLink(model_id, model_type, { campaign_id })}
      </p>
    ),
  },
  tag_changed: {
    title: "Tag Changed",
    icon: "tag",
    message: ({
      user_name,
      contact_name,
      entity_name,
      auditable_type,
      auditable_id,
      case_name,
      case_id,
      info: { removed, tag, data_job_id, machine_id },
    }) => {
      const userName = user_name || "System";
      const action = removed ? "removed" : "added";
      const typeMappings = {
        Contact: contact_name,
        Entity: entity_name,
        Case: case_name,
        Machine: machine_id,
      };
      const typeName = typeMappings[auditable_type];
      const modelLink = getModelLink(auditable_id, auditable_type, {
        label: typeName,
        machine_id,
        case_id,
      });

      return (
        <div>
          {`${userName} ${action} Tag `}
          <RvxTag tag={tag} /> {`${removed ? "from" : "to"} `}
          {typeName ? (
            <span>{modelLink}</span>
          ) : (
            <span>
              {auditable_type} (ID: {auditable_id})
            </span>
          )}
          {data_job_id ? ` via import (DataJob #${data_job_id})` : null}
        </div>
      );
    },
  },
  load: {
    title: "Form Loaded",
    icon: "arrow alternate circle down",
    message: ({ contact_name, contact_id, form_name }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <p>
          {contactLink} loaded {form_name}
        </p>
      );
    },
  },
  save: {
    title: "Form Saved",
    icon: "save",
    message: ({ contact_name, contact_id, form_name }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <p>
          {contactLink} saved {form_name}
        </p>
      );
    },
  },
  converted: {
    title: ({ contact_id }) => `${contact_id ? "Contact" : "Entity"} Converted`,
    icon: "toggle on",
    message: ({ user_name, contact_name, entity_name }) =>
      `${user_name} marked CONVERTED ${contact_name || entity_name}`,
  },
  unconverted: {
    title: ({ contact_id }) =>
      `${contact_id ? "Contact" : "Entity"} Unconverted`,
    icon: "toggle off",
    message: ({ user_name, contact_name, entity_name }) =>
      `${user_name} marked UNCONVERTED ${contact_name || entity_name}`,
  },
  campaign_status_changed: {
    title: ({ contact_id, case_id }) =>
      `${
        contact_id ? "Contact" : case_id ? "Case" : "Entity"
      } Campaign Status Changed`,
    icon: "edit outline",
    message: ({
      user_name,
      contact_name,
      entity_name,
      case_name,
      case_id,
      contact_id,
      entity_id,
      info: {
        prev_status,
        new_status,
        prev_effective_date,
        new_effective_date,
        data_job_id,
      },
    }) => {
      const modelLink = getModelLink(
        contact_id || case_id || entity_id,
        contact_id ? "Contact" : case_id ? "Case" : "Entity",
        { label: contact_name || case_name || entity_name }
      );
      const from_date = prev_effective_date
        ? ` from ${prev_effective_date}`
        : "";
      return (
        <>
          <p>
            {user_name} changed {modelLink}
            {prev_status !== new_status && (
              <>
                {" "}
                from {prev_status} to {new_status}
              </>
            )}
          </p>
          {from_date !== new_effective_date && (
            <p>
              Effective date changed{from_date} to {new_effective_date}
            </p>
          )}
          {data_job_id ? ` via import (DataJob #${data_job_id})` : ""}
        </>
      );
    },
    buttons: props => {
      const {
        campaign_id,
        entity_id,
        contact_id,
        modelType,
        modelId,
        logId,
        refreshLog,
        item,
      } = props;
      const caseId = item.case_id;
      const effectiveDate = item.info.new_effective_date;
      let campaignStatus = item.info.new_status;
      if (campaignStatus === "(unset)") {
        campaignStatus = null;
      }
      return (
        <>
          <CampaignStatusModal
            campaignId={campaign_id}
            campaignStatus={campaignStatus}
            entityId={entity_id}
            contactId={contact_id}
            caseId={caseId}
            effectiveDate={effectiveDate}
            entityName={item.entity_name}
            trigger={
              <Button basic size="tiny" color="red" content="Update Status" />
            }
            updateData={refreshLog}
          />
          <NewNoteModal
            modelType={modelType}
            modelId={modelId}
            refersToModel="AuditLog"
            refersToID={logId}
            trigger={
              <Button basic size="tiny" content="Create Note from Activity" />
            }
          />
        </>
      );
    },
  },

  created: {
    title: ({ category }) => `${titleCase(category)} Created`,
    icon: "plus",
    message: ({
      user_name,
      contact_id,
      contact_name,
      entity_id,
      entity_name,
      case_id,
      case_name,
      info: { value, data_job_id, revenue_opportunity_id, tag, field_name },
      auditable_type,
      campaign_id,
      campaign_name,
      auditable_id,
    }) => {
      return getGenericMessage({
        user_name,
        contact_id,
        contact_name,
        entity_id,
        entity_name,
        auditable_type,
        action: "created",
        value,
        data_job_id,
        tag,
        field_name,
        case_id,
        case_name,
        revenue_opportunity_id,
        campaign_id,
        campaign_name,
        auditable_id,
      });
    },
  },
  changed: {
    title: ({ category }) => `${titleCase(category)} Changed`,
    icon: "edit",
    message: ({
      user_name,
      contact_id,
      contact_name,
      entity_id,
      entity_name,
      case_id,
      case_name,
      info,
      auditable_type,
      auditable_id,
    }) => {
      let { value, changes, data_job_id } = info;
      changes = flattenChanges(changes, {
        phone_numbers: {
          label: "phone number",
        },
        custom_fields: {
          label: "custom field",
          mapValue: value => {
            try {
              if (typeof value.join === "function") {
                return value.join("' : '");
              } else {
                return value;
              }
            } catch (e) {
              console.error(e);
            }
          },
        },
        addresses: {
          label: "address",
        },
        is_emailable: {
          mapLine: ({ value }) => `Made${value ? "" : " not"} emailable`,
        },
        is_callable: {
          mapLine: ({ value }) => `Made${value ? "" : " not"} callable`,
        },
        enable_email: {
          mapLine: ({ value }) => `${value ? "Enabled" : "Disabled"} email`,
        },
        enable_call: {
          mapLine: ({ value }) => `${value ? "Enabled" : "Disabled"} call`,
        },
      });
      return getInfoChangedMessage({
        user_name,
        contact_id,
        contact_name,
        entity_id,
        entity_name,
        changes,
        auditable_type,
        auditable_id,
        value,
        data_job_id,
        case_id,
        case_name,
        info,
      });
    },
  },
  deleted: {
    title: ({ category }) => `${titleCase(category)} Deleted`,
    icon: "trash",
    message: ({
      user_name,
      campaign_id,
      campaign_name,
      contact_id,
      contact_name,
      entity_id,
      entity_name,
      case_id,
      case_name,
      info: { value, data_job_id, revenue_opportunity_id },
      auditable_type,
      auditable_id,
    }) => {
      return getGenericMessage({
        user_name,
        contact_id,
        contact_name,
        entity_id,
        entity_name,
        auditable_type,
        auditable_id,
        action: "deleted",
        value,
        data_job_id,
        case_id,
        case_name,
        campaign_id,
        campaign_name,
        revenue_opportunity_id,
      });
    },
  },

  contact_moved: {
    title: "Contact Moved",
    icon: "random",
    message: ({
      contact_id,
      contact_name,
      entity_id,
      entity_name,
      info: { prev_entity },
    }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      const newEnitityLink = getModelLink(entity_id, "Entity", {
        label: entity_name,
      });
      const oldEntityLink = getModelLink(prev_entity.id, "Entity", {
        label: prev_entity.name,
      });

      return (
        <span>
          Contact {contactLink} moved to {newEnitityLink} from {oldEntityLink}
        </span>
      );
    },
  },

  contact_merged: {
    title: "Contact Merged",
    icon: "object group",
    message: ({
      user_name,
      contact_id,
      contact_name,
      info: { merged_contact },
    }) => {
      const newContactLink = getModelLink(contact_id, "Contact");
      return (
        <div>
          <span>{user_name} Merged contact</span>
          <ul>
            <li key="id">ID: {merged_contact.id}</li>
            <li key="name">Name: {merged_contact.full_name}</li>
          </ul>
          <span>into {newContactLink}</span>
          <ul>
            <li key="id">ID: {contact_id}</li>
            <li key="name">Name: {contact_name}</li>
          </ul>
        </div>
      );
    },
  },

  added_to_campaign: {
    title: ({ contact_id, case_id }) =>
      `${
        contact_id ? "Contact" : case_id ? "Case" : "Entity"
      } added to Campaign`,
    icon: "plus",
    message: ({
      contact_id,
      contact_name,
      entity_id,
      entity_name,
      user_name,
      campaign_id,
      campaign_name,
      case_id,
      case_name,
      info,
    }) => {
      const modelLink = getModelLink(
        contact_id || case_id || entity_id,
        contact_id ? "Contact" : case_id ? "Case" : "Entity",
        { label: contact_name || case_name || entity_name }
      );
      const campaignLink = getModelLink(campaign_id, "Campaign", {
        label: campaign_name,
      });
      return (
        <span>
          {user_name} added {modelLink} to campaign {campaignLink}{" "}
          {info.data_job_id ? ` via import (DataJob #${info.data_job_id})` : ""}
        </span>
      );
    },
  },

  removed_from_campaign: {
    title: ({ contact_id, case_id }) =>
      `${
        contact_id ? "Contact" : case_id ? "Case" : "Entity"
      } removed from Campaign`,
    icon: "trash",
    message: ({
      contact_id,
      contact_name,
      entity_id,
      entity_name,
      user_name,
      campaign_id,
      campaign_name,
      case_id,
      case_name,
      info,
    }) => {
      const modelLink = getModelLink(
        contact_id || case_id || entity_id,
        contact_id ? "Contact" : case_id ? "Case" : "Entity",
        { label: contact_name || case_name || entity_name }
      );
      const campaignLink = getModelLink(campaign_id, "Campaign", {
        label: campaign_name,
      });
      return (
        <span>
          {user_name} removed {modelLink} from campaign {campaignLink}{" "}
          {info.data_job_id ? ` via import (DataJob #${info.data_job_id})` : ""}
        </span>
      );
    },
  },

  entity_merged: {
    title: "Entity Merged",
    icon: "object group",
    message: ({
      user_name,
      entity_id,
      entity_name,
      info: { merged_entity },
    }) => {
      const newEntityLink = getModelLink(entity_id, "Entity");
      return (
        <div>
          <span>{user_name} Merged entity</span>
          <ul>
            <li key="id">ID: {merged_entity.id}</li>
            <li key="name">Name: {merged_entity.name}</li>
          </ul>
          <span>into {newEntityLink}</span>
          <ul>
            <li key="id">ID: {entity_id}</li>
            <li key="name">Name: {entity_name}</li>
          </ul>
        </div>
      );
    },
  },

  ...[
    "unknown",
    "failed_invalid",
    "failed_with_vm",
    "failed_no_vm",
    "failed_with_vm_email_optin",
    "failed_no_vm_email_optin",
    "live_invalid",
    "live_dnc",
    "live_unqualified",
    "live_callback",
    "live_qualified",
    "live_success",
    "call",
    "manual_call",
  ].reduce(
    (accum, status) => ({
      ...accum,
      [status]: {
        title: "Call Status",
        icon: "call",
        message: ({
          id,
          user_name,
          contact_name,
          action,
          contact_id,
          info,
          call_info,
        }) => {
          const {
            previous_disposition,
            new_disposition,
            other_comment,
            callback_time,
            is_manual,
            comments,
            date,
          } = info;
          const contactLink = getModelLink(contact_id, "Contact", {
            label: contact_name,
          });

          const isInbound =
            call_info.call_direction?.toUpperCase() === "INBOUND";
          return (
            <>
              {is_manual && (
                <p>
                  {" "}
                  Model: <b>Call</b>{" "}
                </p>
              )}
              {previous_disposition && new_disposition ? (
                <p>
                  <strong>{user_name}</strong> edited the call disposition of{" "}
                  <strong>{contactLink}</strong> from{" "}
                  <strong>{previous_disposition}</strong> to{" "}
                  <strong>{new_disposition}</strong>
                </p>
              ) : other_comment ? (
                <p>
                  <strong>{user_name}</strong> called{" "}
                  <strong>{contactLink}</strong>. Call status:{" "}
                  <strong>Other</strong>. {other_comment}
                </p>
              ) : (
                <div>
                  {isInbound ? (
                    <>
                      <b>{contactLink}</b> called <b>{user_name}</b>
                    </>
                  ) : (
                    <>
                      <b>{user_name}</b> called <b>{contactLink}</b>
                    </>
                  )}

                  {is_manual && (
                    <div>
                      {" "}
                      Date: <b>{basicDateFormatter(date)}</b>{" "}
                    </div>
                  )}

                  {isInbound ? (
                    <div>
                      Incoming Number: <b>{call_info.from}</b>
                    </div>
                  ) : (
                    <div>
                      Number Called: <b>{call_info.number_called}</b>
                    </div>
                  )}

                  <div>
                    Call direction:{" "}
                    <b>{lodashCapitalize(call_info.call_direction)}</b>
                  </div>
                  <div>
                    {" "}
                    Call status: <strong>{action}</strong>
                  </div>
                  <p>
                    Comments: <b>{comments}</b>
                  </p>
                  {callback_time && (
                    <div>
                      Scheduled to: {defaultDateTimeFormatter(callback_time)}
                    </div>
                  )}
                </div>
              )}
              {call_info.recording_url && !previous_disposition && (
                <div style={{ marginTop: "10px" }}>
                  <AudioElement
                    id={`audio-el-${id}`}
                    src={call_info.recording_url}
                    wide
                  />
                </div>
              )}
            </>
          );
        },
        buttons: ({
          dispositions,
          item,
          campaign_id,
          item: {
            info: {
              campaign_target_call_id,
              previous_disposition,
              recording_url,
              transcript,
            },
          },
          refreshLog,
        }) => {
          return (
            <>
              {!previous_disposition && !!campaign_target_call_id && (
                <EditCallModal
                  callId={campaign_target_call_id}
                  campaignId={campaign_id}
                  callStatuses={dispositions}
                  onSuccess={refreshLog}
                />
              )}
              <Modal
                trigger={
                  <Button
                    basic
                    size="tiny"
                    content="View Transcript"
                    disabled={!transcript}
                  />
                }
                closeOnDimmerClick={false}
                closeIcon
              >
                <Modal.Header>Transcript</Modal.Header>
                <Modal.Content>{transcript}</Modal.Content>
              </Modal>
            </>
          );
        },
      },
    }),
    {}
  ),
  call_status_verified: {
    title: "Call Verified",
    icon: "call",
    message: ({ contact_name, contact_id, user_name }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <p>
          Call status of {contactLink} marked as verified by {user_name}
        </p>
      );
    },
  },
  call_status_unverified: {
    title: "Call Unverified",
    icon: "call",
    message: ({ contact_name, contact_id, user_name }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <p>
          Call status of {contactLink} marked as unverified by {user_name}
        </p>
      );
    },
  },
  mail_mailed: {
    title: "Mail Sent",
    icon: "mail",
    message: ({ contact_name, contact_id }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return <p>{contactLink} was mailed</p>;
    },
  },
  mail_delivered: {
    title: "Mail Delivered",
    icon: "mail",
    message: ({ contact_name, contact_id }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return <p>{contactLink} RECEIVED Mail</p>;
    },
  },
  manual_update: {
    title: "Manual Update",
    icon: "refresh",
    message: ({ info }) => (
      <>
        <p>Manually updated column {info.column}</p>
        <p>Old value: '{info.old_value}'</p>
        <p>New value: {info.new_value}'</p>
      </>
    ),
  },
  cc_tracking: {
    title: "CC Tracking",
    icon: "mail outline",
    message: ({ contact_name, info: { email_data } }) =>
      `A user ${email_data.sender} sent an email with the subject ${email_data.subject} to ${contact_name}, ${email_data.To}`,
    buttons: ({
      modelType,
      modelId,
      logId,
      item: {
        info: { email_data },
      },
    }) => {
      return (
        <>
          <Modal
            trigger={<Button basic size="tiny" content="View Email Sent" />}
            closeOnDimmerClick={false}
            closeIcon
          >
            <Modal.Header>Email Content</Modal.Header>
            <Modal.Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: email_data["stripped-html"],
                }}
              />
            </Modal.Content>
          </Modal>
          <NewNoteModal
            modelType={modelType}
            modelId={modelId}
            refersToModel="AuditLog"
            refersToID={logId}
            trigger={
              <Button basic size="tiny" content="Create Note from Activity" />
            }
          />
        </>
      );
    },
  },
  referred_by_contact: {
    title: "Referred by Contact",
    icon: "address card outline",
    message: ({
      contact_name,
      contact_id,
      info: { referred_by_contact, notes },
    }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <p>
          {contactLink} was referred by {referred_by_contact.full_name}.{" "}
          {notes || ""}
        </p>
      );
    },
  },
  referred_contact: {
    title: "Referred Contact",
    icon: "address card outline",
    message: ({
      contact_name,
      contact_id,
      info: { referred_contact, notes },
    }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <p>
          {contactLink} referred a new contact: {referred_contact.full_name}.{" "}
          {notes || ""}
        </p>
      );
    },
  },
  "task.created": {
    title: "Task Created",
    icon: "tasks",
    message: ({ user_name, info: { sequence_id, sequence_name } }) => (
      <>
        {user_name} created a new Task
        {sequence_id && sequence_name && (
          <>
            {" — Triggered by Sequence "}
            <Link to={`/sequences/${sequence_id}`}>{sequence_name}</Link>
          </>
        )}
      </>
    ),
  },
  "task.modified": {
    title: "Task Modified",
    icon: "tasks",
    message: ({ user_name, info: { changes } }) => {
      if (!changes || changes.length === 0) {
        return `${user_name} modified this Task`;
      }
      changes = flattenChanges(changes, {
        assigned_users: { label: "assignee" },
        attachments: { label: "attachment" },
        users: { label: "private access for user" },
        roles: { label: "private access for role" },
        visibility: {
          mapValue: value =>
            taskConstants.visibilities.find(({ id }) => id === value).name,
        },
        status: {
          mapValue: value =>
            taskConstants.statuses.find(({ id }) => id === value).name,
        },
        priority: {
          mapValue: value =>
            taskConstants.priorities.find(({ id }) => id === value).name,
        },
        task_type: {
          mapValue: value =>
            taskConstants.types.find(({ id }) => id === value).name,
        },
        target: { mapValue: ({ type, name }) => `${type} ${name}` },
      });
      return (
        <>
          <p>{user_name} changed the following info:</p>
          <ul>
            {changes.map((change, index) => (
              <li key={index}>{change}</li>
            ))}
          </ul>
        </>
      );
    },
  },
  "task.completed": {
    title: "Task Completed",
    icon: "tasks",
    message: ({ user_name }) => `Marked as completed by ${user_name}`,
  },
  data_job_created: {
    title: "Data Job Created",
    icon: "file outline",
    message: ({ info: { user } }) =>
      `Created DataJob by user ${user.full_name}`,
  },
  data_job_error: {
    title: "Data Job Error",
    icon: "file outline",
    message: () => `An error occurred while processing Data Job`,
  },
  data_job_progress: {
    title: "Data Job Progress",
    icon: "file outline",
    message: ({ info: { status } }) =>
      `Data Job Import is in progress with status of: ${status}`,
  },
  data_job_completed: {
    title: "Data Job Completed",
    icon: "file outline",
    message: () => `Data Job Import has been completed`,
  },
  data_job_deleted: {
    title: "Data Job Deleted",
    icon: "file outline",
    message: () => `Data Job has been deleted`,
  },

  ...["assigned_user", "unassigned_user"].reduce(
    (accum, status) => ({
      ...accum,
      [status]: {
        title: ({ category }) => {
          const action = capitalize(status.split("_")[0]);
          return `${category === "case" ? "Investigator" : "User"} ${action}`;
        },
        icon: "file outline",
        message: ({ user_name: userName, info }) => {
          const action = status.split("_")[0];
          const user = info[status];
          const dataJobId = info.data_job_id;
          const dataJobMessage = dataJobId
            ? ` (Data Job ID: ${dataJobId})`
            : "";
          return (
            <span>
              {user.name} was {action} by {userName}
              {dataJobMessage}
            </span>
          );
        },
      },
    }),
    {}
  ),

  lead_added_to_case: {
    title: "Lead added to Case",
    icon: "edit",
    message: ({ info: { lead_entity }, user_name }) => {
      return (
        <div>
          <p>
            <em>{lead_entity.name}</em> was added to the case by{" "}
            <em>{user_name}</em>
          </p>
        </div>
      );
    },
  },

  text_whatsapp_sent: {
    title: "Manual Activity Created",
    icon: "edit",
    message: ({
      contact_id,
      contact_name,
      user_name,
      info: { phone_number, comment, conversation_date },
    }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <div>
          <p>
            Model: <b>WhatsApp</b>
          </p>
          <div>
            <strong>{user_name}</strong> had a WhatsApp conversation with{" "}
            <b>{contactLink}</b>
          </div>
          <div>
            Date: <b>{basicDateFormatter(conversation_date)}</b>
          </div>
          <div>
            Phone number: <b>{phone_number}</b>
          </div>
          <div>
            Comments: <b>{comment}</b>
          </div>
        </div>
      );
    },
    buttons: ({
      item: {
        info: { transcript },
      },
    }) => {
      return (
        <>
          <Modal
            trigger={
              <Button
                basic
                size="tiny"
                content="View Transcript"
                disabled={!transcript}
              />
            }
            closeOnDimmerClick={false}
            closeIcon
          >
            <Modal.Header>Transcript</Modal.Header>
            <Modal.Content>{transcript}</Modal.Content>
          </Modal>
        </>
      );
    },
  },

  text_sms_sent: {
    title: "Manual Activity Created",
    icon: "edit",
    message: ({
      contact_id,
      contact_name,
      user_name,
      info: { phone_number, comment, conversation_date },
    }) => {
      const contactLink = getModelLink(contact_id, "Contact", {
        label: contact_name,
      });
      return (
        <div>
          <p>
            Model: <b>Text</b>
          </p>
          <div>
            <strong>{user_name}</strong> had a Text conversation with{" "}
            <b>{contactLink}</b>
          </div>
          <div>
            Date: <b>{basicDateFormatter(conversation_date)}</b>
          </div>
          <div>
            Phone number: <b>{phone_number}</b>
          </div>
          <div>
            Comments: <b>{comment}</b>
          </div>
        </div>
      );
    },
    buttons: ({
      item: {
        info: { transcript },
      },
    }) => {
      return (
        <>
          <Modal
            trigger={
              <Button
                basic
                size="tiny"
                content="View Transcript"
                disabled={!transcript}
              />
            }
            closeOnDimmerClick={false}
            closeIcon
          >
            <Modal.Header>Transcript</Modal.Header>
            <Modal.Content>{transcript}</Modal.Content>
          </Modal>
        </>
      );
    },
  },

  email: {
    title: "Manual Activity Created",
    icon: "edit",
    message: ({ contact_name }) => {
      return (
        <div>
          <p>
            <strong>Model:</strong> Email
          </p>
          <p>
            <strong>{contact_name}</strong> was sent an email
          </p>
        </div>
      );
    },
    buttons: ({
      item: {
        info: { contents },
      },
    }) => {
      return (
        <>
          <Modal
            trigger={
              <Button
                basic
                size="tiny"
                content="View EML Contents"
                disabled={!contents}
              />
            }
            closeOnDimmerClick={false}
            closeIcon
          >
            <Modal.Header>Email Contents</Modal.Header>
            <Modal.Content>{contents}</Modal.Content>
          </Modal>
        </>
      );
    },
  },
};

const modelMap = {
  Campaign: "campaigns",
  CampaignTarget: "campaigns/{campaign_id}/target",
  Entity: "entities",
  Contact: "contacts",
  Case: "cases",
  CampaignCase: "campaigns/{campaign_id}/cases",
  RevenueOpportunity: "revenue-opportunities",
};

function getAttributeForItem(item, attribute) {
  let action;
  if (item.category === "call" && !item.action.startsWith("call_status")) {
    action = item.category;
  } else {
    action = item.action;
  }
  const attributes = actionMap[action] || [];

  if (item?.info?.is_manual) {
    attributes.title = "Manual Activity Created";
    attributes.icon = "edit";
  }

  return attributes[attribute] || (attribute === "title" ? action : "");
}

function getModelLink(
  model_id,
  model_type,
  { campaign_id = "", label = "", machine_id, case_id } = {}
) {
  if (model_type.toLowerCase() === "machine") {
    return getMachineModelLink(case_id, machine_id);
  }

  const path = modelMap[model_type].replace("{campaign_id}", campaign_id);
  return (
    <Link to={`/${path}/${model_id}`}>{label || startCase(model_type)}</Link>
  );
}

function getMachineModelLink(caseId, machineId) {
  return (
    <Link
      to={`/cases/${caseId}/machines?page=1&per_page=20&machine_id=${machineId}`}
    >
      Machine ID: ({machineId})
    </Link>
  );
}

function getTitle(item) {
  const getTitleFn = getAttributeForItem(item, "title");
  return isFunction(getTitleFn) ? getTitleFn(item) : getTitleFn;
}

function getMessagePart(message, part) {
  if (!message || !part) {
    return "";
  }
  part = part.toLowerCase();
  if (["detail", "text", "plain"].includes(part)) {
    part = "text";
  } else if (["email", "html", "rich"].includes(part)) {
    part = "html";
  } else {
    return "";
  }
  const doctypeIndex = message.search(/<!doctype html/i);
  if (doctypeIndex === -1) {
    return "";
  }
  message =
    part === "html"
      ? message.slice(doctypeIndex)
      : message.slice(0, doctypeIndex);
  return message.replace(
    /^\s*(?:<\/?br ?\/?>\s*)+|\s*(?:<\/?br ?\/?>\s*)+$/gi,
    ""
  );
}

function getMessage(item) {
  const getFrontendMessage = getAttributeForItem(item, "message");
  const frontendMessage = isFunction(getFrontendMessage)
    ? getFrontendMessage(item)
    : getFrontendMessage;
  return frontendMessage || getMessagePart(item.info.message, "detail");
}

function getGenericMessage({
  user_name,
  contact_id,
  contact_name,
  entity_id,
  entity_name,
  auditable_type,
  value,
  action,
  campaign_id,
  campaign_name,
  data_job_id = null,
  tag = null,
  field_name = null,
  case_id = null,
  case_name = null,
  revenue_opportunity_id = null,
}) {
  const user = user_name || "System";
  let model = revenue_opportunity_id ? (
    <span>Revenue Opportunity (ID: {revenue_opportunity_id})</span>
  ) : contact_id ? (
    <>contact {getModelLink(contact_id, "Contact", { label: contact_name })}</>
  ) : case_id ? (
    <>case {getModelLink(case_id, "Case", { label: case_name })}</>
  ) : (
    <>entity {getModelLink(entity_id, "Entity", { label: entity_name })}</>
  );

  let message;
  let modelMessage = <>{model}</>;

  if (data_job_id) {
    modelMessage = (
      <>
        {modelMessage} (Data Job ID: {data_job_id})
      </>
    );
  }

  let onTag = "";
  if (tag) {
    onTag = (
      <>
        {" on Tag"} <RvxTag tag={tag} />
      </>
    );
  }

  if (auditable_type === "TagMetadataValue" && field_name) {
    message = (
      <>
        {"metadata '"}
        {field_name}
        {"', with the value '"}
        {value}
        {"' attached to "}
        {modelMessage}
      </>
    );
  } else if (auditable_type && !(auditable_type in modelMap)) {
    message = (
      <>
        {lodashStartCase(auditable_type).toLowerCase()} '{value}' attached to{" "}
        {modelMessage}
      </>
    );
  } else {
    message = modelMessage;
  }

  if (auditable_type === "CampaignCase") {
    if (action === "deleted") {
      message = (
        <>
          {message} from{" "}
          {getModelLink(campaign_id, "Campaign", { label: campaign_name })}
        </>
      );
    }
  }

  if (auditable_type === "RevenueOpportunity") {
    return getRevenueOpportunityGenericMessage({
      ...arguments[0],
      user,
      onTag,
    });
  }

  return (
    <>
      {user} {action}
      {onTag} the following {message}
    </>
  );
}

function getRevenueOpportunityGenericMessage({
  user,
  action,
  auditable_id,
  entity_id,
  entity_name,
  campaign_name,
  campaign_id,
  case_id,
  case_name,
  data_job_id,
  onTag,
}) {
  const baseMessage = (
    <>
      {user} {action} {onTag} a Revenue Opportunity (
      {getModelLink(auditable_id, "RevenueOpportunity", {
        label: `#${auditable_id}`,
      })}
      ) on the following entity:{" "}
      {getModelLink(entity_id, "Entity", { label: entity_name })}
    </>
  );

  const campaignMsg = () => (
    <>
      {" "}
      campaign:{" "}
      {getModelLink(campaign_id, "Campaign", { label: campaign_name })}
    </>
  );

  const caseMsg = () => (
    <> case: {getModelLink(case_id, "Case", { label: case_name })}</>
  );

  const dataJobMsg = data_job_id ? <>(Data Job ID: {data_job_id})</> : "";

  let additionalMessage;

  if (campaign_id && !case_id) {
    additionalMessage = <> and {campaignMsg()}</>;
  }

  if (!campaign_id && case_id) {
    additionalMessage = <> and {caseMsg()}</>;
  }

  if (campaign_id && case_id) {
    additionalMessage = (
      <>
        , {campaignMsg()} and {caseMsg()}
      </>
    );
  }

  return (
    <>
      {baseMessage}
      {additionalMessage} {dataJobMsg}
    </>
  );
}

function getInfoChangedMessage({
  user_name,
  contact_id,
  contact_name,
  entity_id,
  entity_name,
  changes,
  auditable_type,
  auditable_value = null,
  auditable_id,
  data_job_id = null,
  case_id = null,
  case_name = null,
  info = null,
}) {
  const user = user_name || "System";
  const revenue_opportunity_id =
    auditable_type === "RevenueOpportunity" && auditable_id;
  const machine_id = auditable_type === "Machine" && info.machine_id;

  let model = revenue_opportunity_id ? (
    <span>Revenue Opportunity (ID: {revenue_opportunity_id})</span>
  ) : machine_id ? (
    <>
      {" "}
      {getModelLink(case_id, "Machine", {
        label: machine_id,
        machine_id,
        case_id,
      })}
    </>
  ) : contact_id ? (
    <>contact {getModelLink(contact_id, "Contact", { label: contact_name })}</>
  ) : case_id ? (
    <>case {getModelLink(case_id, "Case", { label: case_name })}</>
  ) : (
    <>entity {getModelLink(entity_id, "Entity", { label: entity_name })}</>
  );
  if (auditable_type && !(auditable_type in modelMap)) {
    let value = auditable_value;
    if (auditable_type === "EmailAddress") {
      value = info.value;
    }

    if (auditable_type === "Machine") {
      model = (
        <>
          {model} attached to case{" "}
          {getModelLink(case_id, "Case", { label: case_name })}
        </>
      );
    } else {
      model = (
        <>
          {lodashStartCase(auditable_type).toLowerCase()}
          {value ? ` '${value}'` : ""} attached to {model}
        </>
      );
    }
  }
  return (
    <>
      {isEmpty(changes) ? (
        <p>
          {user} changed {model}{" "}
        </p>
      ) : (
        <>
          <p>
            {user} changed{" "}
            {info?.field_name ? (
              <span>
                the value for <strong>{info.field_name}</strong> on tag{" "}
                <RvxTag tag={info?.tag} />{" "}
              </span>
            ) : (
              "the following info"
            )}{" "}
          </p>
          <ul>
            {changes.map((change, index) => (
              <li key={index}>{change}</li>
            ))}
          </ul>
          <p>
            of {model}{" "}
            {data_job_id ? ` via import (DataJob #${data_job_id})` : null}
          </p>
        </>
      )}
    </>
  );
}

function flattenChanges(changes, attributes = {}) {
  return (changes || []).flatMap(
    ({
      attribute,
      value,
      previous_value: prevValue,
      type,
      added = [],
      removed = [],
      modified = [],
      deleted = [],
    }) => {
      const options = attributes[attribute] || {};
      const label = options.label || attribute?.replace(/_/g, " ");
      const valueMapper = value => {
        if (!value) {
          return value;
        }
        if (type === "DateTime") {
          value = createDateTimeFormatter({ timeZone: value.timezone })(
            value.isodate
          );
        }
        if (
          value["new_field"] &&
          value["original_field"] &&
          !value["new_value"]
        ) {
          value = `the key of custom field from '${value.original_field}' to '${value.new_field}' `;
        }
        if (
          value["field"] &&
          (value["new_value"] || value["new_value"] === false) &&
          (value["original_value"] || value["original_value"] === false)
        ) {
          value = `the value of '${value["field"]}' from '${value.original_value}' to '${value.new_value}'`;
        }
        if (value["deleted_field"]) {
          value = `'${value["deleted_field"]}'`;
        }
        const mapper = options.mapValue;
        return mapper ? mapper(value) : value;
      };
      prevValue = valueMapper(prevValue);
      value = valueMapper(value);
      const action =
        !isNil(prevValue) && !isNil(value) && prevValue !== value
          ? "Changed"
          : !isNil(value)
          ? "Set"
          : "Unset";

      const line = ({ action, label, prevValue, value, addRemove }) => {
        const shownValue = roundToTwoDecimals(value);
        const shownPrevValue = roundToTwoDecimals(prevValue);
        return addRemove
          ? `${action} ${label} '${shownValue}'`
          : modified.length > 0
          ? `${action} ${shownValue}`
          : `${action} ${label} ${
              !isNil(prevValue) ? `from '${shownPrevValue}' ` : ""
            }${!isNil(value) ? `to '${shownValue}'` : ""}`;
      };

      let changes = [
        ...(value !== undefined ? [{ prevValue, value, action, label }] : []),
        ...added.map(value => ({
          action: "Added",
          addRemove: true,
          label,
          value: valueMapper(value),
        })),
        ...removed.map(value => ({
          action: "Removed",
          addRemove: true,
          label,
          value: valueMapper(value),
        })),
        ...modified.map(value => ({
          action: "Modified",
          modified: true,
          label,
          value: valueMapper(value),
        })),
        ...deleted.map(value => ({
          action: "Deleted",
          label,
          value: valueMapper(value),
        })),
      ];
      changes = changes.map(opts => {
        const mapper = options.mapLine;
        return mapper ? mapper(opts) : line(opts);
      });
      return changes;
    }
  );
}

const AttachmentDropdown = ({ attachments }) => {
  return (
    <CustomDropdown
      className="email-attachment"
      icon="attach"
      scrolling
      button
      open={true}
    >
      <Dropdown.Menu direction="left">
        {attachments.map((attachment, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => window.open(attachment["url"], "_blank")}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "right",
            }}
          >
            {attachment.file_name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </CustomDropdown>
  );
};

const LogItem = ({
  item,
  modelType,
  modelId,
  activeItem,
  dispositions,
  refreshLog,
  categories,
}) => {
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (activeItem) {
      ReactDOM.findDOMNode(ref.current).scrollIntoView({ behavior: "smooth" });
    }
  }, [activeItem]);

  const {
    auditable_id,
    entity_id,
    contact_id,
    id,
    ctfr_id,
    ctfr_info,
    call_notes,
    form_language,
    number_called,
  } = item;
  const title = getTitle(item);
  const icon = getAttributeForItem(item, "icon");
  const date = formatDate(item.created_at);
  const message = getMessage(item);
  const email = getMessagePart(item.info.message, "email");
  const Buttons = getAttributeForItem(item, "buttons");
  const campaign = {
    id: item.campaign_id,
    name: item.campaign_name,
    deleted: item.campaign_deleted,
  };

  const showMergedLabel =
    item.info["_merged_from_contact"] || item.info["_merged_from_entity"];

  const showShowNotesButton = call_notes.length ? (
    <>
      <Grid.Row className="buttons">
        <Button basic onClick={() => setIsNotesModalVisible(true)}>
          View Notes
        </Button>
      </Grid.Row>
      <Modal
        closeIcon
        open={isNotesModalVisible}
        onClose={() => setIsNotesModalVisible(false)}
      >
        <Modal.Header>Call notes</Modal.Header>
        <Modal.Content className="tabView">
          <Card.Group>
            {call_notes.map((note, index) => (
              <NoteItem
                key={index}
                note={note}
                categories={categories}
                onChange={refreshLog}
              />
            ))}
          </Card.Group>
        </Modal.Content>
      </Modal>
    </>
  ) : null;

  return (
    <Card fluid ref={ref} className={activeItem ? "highlight brand" : "brand"}>
      <Card.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column className="icon">
              <Icon name={icon} />
            </Grid.Column>
            <Grid.Column className="main">
              <Card.Header>{title}</Card.Header>
              <span>{date}</span>
              {campaign.id && (
                <span>
                  Campaign:{" "}
                  {campaign.id === 0 || campaign.deleted ? (
                    campaign.name
                  ) : (
                    <Link to={`/campaigns/${campaign.id}`}>
                      {campaign.name}
                    </Link>
                  )}
                </span>
              )}
              {message && <div className="message">{message}</div>}
            </Grid.Column>
            <Grid.Column className="merged">
              {showMergedLabel && <MergedLabel item={item} />}
              {item.email_attachments.length > 0 && (
                <AttachmentDropdown attachments={item.email_attachments} />
              )}
            </Grid.Column>
          </Grid.Row>
          {showShowNotesButton}
          {Buttons && (
            <Grid.Row className="buttons">
              <Buttons
                campaign_id={campaign.id}
                entity_id={entity_id}
                email={email}
                logId={id}
                modelType={modelType}
                modelId={modelId}
                ctfr_id={ctfr_id}
                ctfr_info={ctfr_info}
                form_language={form_language}
                contact_id={contact_id}
                auditable_id={auditable_id}
                dispositions={dispositions}
                item={item}
                refreshLog={refreshLog}
              />
            </Grid.Row>
          )}
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default LogItem;
